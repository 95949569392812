<template>
  <div class="home">

    <HeaderView/>

    <section class="banner">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 align-self-center">
            <div class="content-wrapper">
              <h1>Merwylan Software <br> Development</h1>
              <p class="p">Software op maat tegen een scherpe prijs</p>
              <a href="contact" class="btn-style">Vraag een offerte aan
                <img src="../assets/images/icon/right-arrow.png" alt="right-arrow">
              </a>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 align-self-center">
            <div class="img-wrapper">
              <div class="play" data-bs-toggle="modal"
                   data-bs-target="#banner-popup">
                <img src="../assets/images/icon/play-icon.svg" alt="img">
              </div>
              <img src="../assets/images/banner-bg-img.png" class="main-img" alt="img">
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="modal fade" id="banner-popup" tabindex="-1" aria-labelledby="reminderModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
           <div class="video-wrapper">
             <video class="mh-100 mw-100" controls title="Merwylan Software Development" preload="metadata" allowfullscreen>
               <source  src="../assets/images/merwylan.mp4#t=0.5" type="video/mp4">
             </video>
           </div>
          </div>
        </div>
      </div>
    </div>

    <section class="services">
      <div class="container">
        <h2>Onze Werkzaamheden</h2>
        <ServicesCard/>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="content-wrapper">
          <h2>Onze doelstelling</h2>
          <p>
            Wij werken nauw met u samen als uw digitale partner
            in innovatie om uw bedrijfsdoelstellingen te begrijpen
            en op maat gemaakte oplossingen te bieden die uw bedrijf
            naar een hoger niveau tillen. Onze slimme software voor slimme
            bedrijven helpt u om uw bedrijfsprocessen te stroomlijnen en de
            productiviteit te verhogen. Omdat betrouwbare software onze kernwaarde
            is, kunt u altijd op ons rekenen voor betrouwbare resultaten. Of u nu op
            zoek bent naar efficiëntieverbetering, innovatie of groei, wij hebben de
            tools en de expertise om u te helpen slagen. Laten we samen een succesvolle
            toekomst creëren als uw digitale partner.
          </p>
          <img src="../assets/images/goals.webp" alt="laptop usage">
        </div>
      </div>
    </section>

    <AccordianView/>

    <section class="footer-header">
      <div class="container">
        <div class="footer-header-wrapper">
          <h2>Verander vandaag de wereld van morgen,<br> met onze op maat gemaakte software</h2>
          <a href="contact" class="btn-style gray">Vraag een offerte aan
            <img src="../assets/images/icon/right-arrow.png" alt="right-arrow">
          </a>
        </div>
      </div>
    </section>

    <section class="get-in-touch get-in">
      <div class="container">
        <div class="content-wrapper" >
          <h2 style="text-align:center">Begin vandaag uw succes van morgen</h2>
          <a href="contact" class="btn-style gray mt-4">Vraag een offerte aan
            <img src="../assets/images/icon/right-arrow.png" alt="right-arrow">
          </a>
        </div>
      </div>
    </section>

    <FooterView/>


  </div>
</template>

<script>


import HeaderView from "@/components/HeaderView.vue";
import AccordianView from "@/components/AccordianView.vue";
import ServicesCard from "@/components/ServicesCard.vue";
import FooterView from "@/components/FooterView.vue";
export default {
  name: 'HomeView',
  components: {
    HeaderView,
    AccordianView,
    ServicesCard,
    FooterView,
  }
}
</script>
