<template>
  <section class="faq">
    <div class="container">
      <div class="faq-header">
        <h2>Veelgestelde Vragen</h2>
      </div>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
            >
              Hoeveel kost het om mijn software te ontwikkelen?
            </button>
          </h2>
          <div
              id="collapseEight"
              class="accordion-collapse collapse show"
              data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Over het algemeen rekenen wij 80 euro per uur. Soms willen we ook met vaste bedragen werken, mits het project goed omschreven is.
                Neem gerust contact met ons op om een vrijblijvende offerte te ontvangen.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="false"
                aria-controls="collapseNine"
            >
              Hoelang duurt het om het project af te ronden?
            </button>
          </h2>
          <div
              id="collapseNine"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Elk project is verschillend, maar wij staan bekend om onze recht-toe-recht-aan mentaliteit. Niets is te moeilijk en klagen doen we niet.
                
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="false"
                aria-controls="collapseTen"
            >
              In welke soorten software zijn jullie gespecialiseerd?
            </button>
          </h2>
          <div
              id="collapseTen"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                In de backend zijn wij gespecialiseerd in C# (.NET) en voor de frontend gebruiken we meestal Vue, Svelte, React, Blazor of Angular. 
                Maar in principe zijn wij polygloten; we vinden het leuk om andere talen en technieken te gebruiken en staan daar altijd open voor.
                <br>
                Wij ontwikkelen API integraties, softwaresystemen zoals voorraadsystemen of software in de robotica en kunstmatige intelligentie. We bouwen ook websites, Windows applicaties, en mobiele applicaties. 
                Alles binnen het domein "Software".
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEleven"
                aria-expanded="false"
                aria-controls="collapseEleven"
            >
              Kunnen jullie helpen bij het onderhoud en de ondersteuning van software?
            </button>
          </h2>
          <div
              id="collapseEleven"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Ja, we bieden doorlopende ondersteunings-
                en onderhoudsdiensten voor de software die we ontwikkelen.
                We kunnen verschillende diensten leveren,
                zoals bugfixes, updates, beveiligingspatches en prestatie-optimalisatie.
                We bieden ook service level agreements (SLA's)
                om ervoor te zorgen dat u het gewenste ondersteuningsniveau ontvangt.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwelve"
                aria-expanded="false"
                aria-controls="collapseTwelve"
            >
              Hoe gaan jullie om met projectcommunicatie en samenwerking?
            </button>
          </h2>
          <div
              id="collapseTwelve"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Communicatie en samenwerking staan bij ons op één. Dat begint eigenlijk al bij de eerste gesprekken. 
                We moeten samen een goed plan opmaken; software ontwikkelen doe je niet alleen.
                Zodra we een plan hebben is het aan ons om er een geweldig product van te maken. Wanneer wij denken dat allowfullscreen
                perfect is, laten we het zien en krijgen we graag feedback. Wij gaan door totdat je helemaal tevreden bent.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'AccordianView',
  components: {

  },
  data() {
    return{
      isMenuActive: false,
    }
  }
}
</script>
