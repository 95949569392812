<template>
  <header>
    <div class="container">
      <div class="row">
        <div class="col-md-4 align-self-center">
          <div class="hamburger" @click="isMenuActive = true">
            <img src="../assets/images/icon/hamburger2.svg" alt="Hamburger">
          </div>
          <div class="main-logo">
            <RouterLink to="/">
              <img src="../assets/images/logo.svg" class="img-fluid" alt="Main Logo">
            </RouterLink>
          </div>
        </div>
        <div class="col-md-8 text-end align-self-center">
          <div class="navbar-menu" :class="{'active' : isMenuActive}">
            <div class="close-menu" @click="isMenuActive = false">
              <i class="fa-solid fa-x"></i>
            </div>
            <ul class="menu">
              <li><RouterLink to="/">Home</RouterLink></li>
              <li><RouterLink to="/cases">Cases</RouterLink></li>
              <li><RouterLink to="/werkwijze">Werkwijze</RouterLink></li>
              <li><RouterLink to="/over-ons">Over ons</RouterLink></li>
              <li><RouterLink to="/contact">Contact</RouterLink></li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: 'PrimaryHeader',
  components: {

  },
  data() {
    return{
      isMenuActive: false,
    }
  }
}
</script>
