<template>
  <div class="row">
    <div class="col-md-4" v-for="(item, index) in serviceCard" :key="index">
      <div class="service-cards cases">
        <div class="img-wrapper">
          <img :src="item.image" alt="meeting">
        </div>
        <div class="content-wrapper">
          <h2 class="h2" v-html="item.title"></h2>
          <p v-html="item.text"></p>
        </div>
        <div class="btn-wrapper">
          <Router-link :to="item.btnlink" class="btn-style">LEES MEER</Router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ServicesCard',
  components: {

  },
  data() {
    return{
      serviceCard: [
        {
          image: require('@/assets/images/software-op-maat.webp'),
          title: 'Software op maat',
          text: 'Is uw bedrijf toe aan een gloednieuw systeem dat volledig aan uw wensen voldoet? Merwylan heeft veel ervaring met het ontwikkelen van software.',
          btnlink: '/werkwijze',
        },
        {
          image: require('@/assets/images/doorontwikkelen.webp'),
          title: 'Doorontwikkelen van software',
          text: 'Ook als u bestaande software van een andere partij hebt, kunt u bij ons terecht! Wij kunnen de software verbeteren en nieuwe functionaliteiten toevoegen.',
          btnlink: '/werkwijze',
        },
        {
          image: require('@/assets/images/consultancy.webp'),
          title: 'Consultancy',
          text: 'Wilt u advies over software? Welk bestaand pakket past goed bij uw onderneming? Wij zijn u graag van dienst en kunnen zelfs alle systemen met elkaar koppelen.',
          btnlink: '/werkwijze',
        },
      ],
    }
  }
}
</script>
