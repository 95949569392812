<template>
  <section class="footer">
    <div class="container">
      <div class="footer-wrapper">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <div class="footer-logo">
              <RouterLink to="/">
                <img src="../assets/images/footer-logo.png" alt="logo">
              </RouterLink>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 ">
            <h3 class="">Cases</h3>
            <ul>
              <li><a href="/cases/logistiek">Transport & Logistiek</a></li>
              <li><a href="/cases/metaalbewerking">Metaalbewerking</a></li>
              <li><a href="/cases/ai">Kunstmatige Intelligentie (AI)</a></li>
            </ul>
          </div>
          <div class="col-lg-3 col-md-4 ">
            <h3 class="">Soorten applicaties</h3>
            <ul>
              <li><a href="/cases">Web Applicaties & Websites</a></li>
              <li><a href="/cases">Android & IOS</a></li>
              <li><a href="/cases">Desktop applicaties</a></li>
              <li><a href="/cases">Automatisering & API's</a></li>

            </ul>
          </div>
          <div class="col-lg-3 col-md-4 ">
            <h3 class="">Overig</h3>
            <ul>
              <li><a href="/over-ons">Over Ons</a></li>
              <li><a href="/contact">Contact</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bottom-footer">
        <p>© 2023 Merwylan Software Development. All rights reserved.</p>
        <a style="color:white" href="/Terms and Conditions Merwylan.pdf">Lees hier onze algemene voorwaarden</a>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'FooterView',
  components: {

  },
  data() {
    return{
      isMenuActive: false,
    }
  }
}
</script>
